<template>
    <div>
        <div v-if="has_carousel">
            <carousel
              :perPage=1
              :autoplay=true
              :autoplayTimeout=3000
              :paginationEnabled=true
              :loop=true
            >
                <slide v-for="(image, index) in get_selected_location.images_header" :key="'image-'+index">
                    <div class="banner page_banner" :style="{backgroundImage:'url(https://cdn.basisam.nl/images/get.php?f='+image+'&w=615&e=jpg&d=schoolenschoolreis.nl)'}"></div>
                </slide>
            </carousel>
        </div>
        <iframe v-if="get_selected_location.video_id" :src="'https://www.youtube.com/embed/'+get_selected_location.video_id" frameborder="0" allow="autoplay; encrypted-media; picture-in-picture" allowfullscreen class="yt-video"></iframe>
        <div class="banner page_banner" :style="banner" v-if="!has_carousel && !get_selected_location.video_id && get_selected_location.images_header"></div>
        <div class="banner page_banner" style="display:flex;justify-content:center;align-items:center" v-if="!get_selected_location.images_header && !get_selected_location.video_id">
          <img src="images/loader.gif" alt="">
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "Itembanner",
  components: {
    Carousel,
    Slide
  },
  computed: {
     ...mapGetters([
      "get_config",
      "get_selected_location",
      'get_image_size_multiplier',
    ]),
    has_carousel(){
      if(this.get_selected_location.hasOwnProperty('images_header')){
        if(this.get_selected_location.images_header.length > 1){
          return true;
        }
      }
      return false;
    },
    banner() {
      let width = 760 * this.get_image_size_multiplier;
      return 'background-image: url("https://cdn.basisam.nl/images/get.php?f='+this.get_selected_location.imglarge+'&w='+width+'&e=jpg&d='+this.get_config.images_cdn+'")';
    },
  }
};
</script>

<style>
.VueCarousel{
  margin-top:5px;
}
.VueCarousel-pagination{
  position:absolute;
  bottom:0;
}
.VueCarousel-dot{
  outline:none !important;
}
.VueCarousel-dot--active{
  background-color: black !important;
}
@media only screen and (max-width:900px){
  .VueCarousel{
    width:100%;
    height:auto;
  }
}
</style>

