<template>
  <div class="NotFound">
    <Aside />

    <div class="page_container">
      <div class="page__wrapper">
        <img src="/images/404.gif" alt="">
        <div class="text-larger">Helaas konden we de pagina die je zoekt niet vinden.</div>
        <div class="spacer small"></div>
        <div><span class="text_link" @click="reload">Klik hier om terug te gaan</span>.</div>
      </div>
    </div>

  </div>
</template>

<script>
import Aside from "../components/Aside.vue";
import { mapActions } from 'vuex'

export default {
  name: "NotFound",
  components: {Aside},
  methods: {
    ...mapActions({reload_home: 'reload_home'}),
    reload() {
      this.reload_home().then((response) => {
        if (response == 'success') { this.$router.push({path: '/'}); }
      })
    }
  }
};
</script>