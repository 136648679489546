<template>
  <div>
    <slider animation="fade" :stopOnHover="true" :interval="5000" height="19vw" :indicators="indicators">
    <slider-item
    v-for="(i, index) in list"
    :key="index"
    >
    <a :href="i.url" :target="i.target"><img :src="'images/headers/'+i.image+'.jpg'" :srcset="'images/headers/'+i.image+'.jpg 1x, images/headers/'+i.image+'@2x.jpg 2x'" alt="" class="header-image"></a>
    </slider-item>
    </slider>
    <!-- <div v-for="(item, index) in all_items" style="border:solid 1px red" :key="index">
      {{item}}<br>
      <img :src="item" alt="">
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Banner',
  props: {
    image: String
  },
  data() {
    return {
      clusters:{
        'set-1':[
          ['schoolenschoolreis-2023',''],
          ['snowworld','2016'],
          ['dungeon','1107'],
          ['museon-omniversum','1078'],
          ['wildlands','3456'],
        ],
        'set-2':[
          ['airborne','1022'],
          ['aalbertshoeve','1045'],
          ['julianatoren','3710'],
          ['sealife','3382'],
          ['schoolenschoolreis-2023',''],
        ],
        'set-3':[
          ['billybird','1030'],
          ['funforest','1059'],
          ['snowworld','2016'],
          ['ouwehands','1084'],
          ['zuiderzeemuseum','1115'],
        ],
        'set-4':[
          ['tussauds','1075'],
          ['bodyworlds','1032'],
          ['klimbos','1072'],
          ['archeon','1024'],
          ['mini-europe','1076'],
        ],
        'set-5':[
          ['lego','3383'],
          ['nmm','1080'],
          ['schoolenschoolreis-2023',''],
          ['slagharen','1026'],
          ['kasteelruine','1103'],
        ],
        'set-6':[
          ['oudvalkeveen','1099'],
          ['snowworld','2016'],
          ['loevestein','1096'],
          ['remastered','2867'],
          ['textielmuseum','1106'],
        ],
        'set-7':[
          ['museon-omniversum','1078'],
          ['geofort','2858'],
          ['heino','1105'],
          ['molenwaard','1027'],
          ['dinoland','1052'],
        ],
        'set-8':[
          ['julianatoren','3710'],
          ['tussauds','1075'],
          ['snowworld','2016'],
          ['sportiom','2868'],
          ['thisisholland','1108'],
        ],
        'set-9':[
          ['dungeon','1107'],
          ['schoolenschoolreis-2023',''],
          ['nmm','1080'],
          ['oudvalkeveen','1099'],
          ['ripleys','1091'],
        ],
        'set-10':[
          ['sealife','3382'],
          ['gigakonijn','1062'],
          ['dpamersfoort','1049'],
          ['billybird','1030'],
          ['schoolenschoolreis-2023',''],
        ],
        'set-11':[
          ['linnaeushof','3711'],
          ['funforest','1059'],
          ['snowworld','2016'],
          ['airborne','1022'],
          ['lovers','1074'],
        ],
        'set-12':[
          ['slagharen','1026'],
          ['youseum','2870'],
          ['schoolenschoolreis-2023',''],
          ['wildlands','3456'],
          ['lego','3383'],
        ],
        'set-13':[
          ['loevestein','1096'],
          ['zuiderzeemuseum','1115'],
          ['klas','3717'],
          ['schoolenschoolreis-2023',''],
          ['tussauds','1075'],
        ],
        'set-14':[
          ['thisisholland','1108'],
          ['schoolenschoolreis-2023',''],
          ['snowworld','2016'],
          ['wildlands','3456'],
        ],
      },
      schedule: [
        'set-2',
        'set-3',
        'set-4',
        'set-5',
        'set-6',
        'set-7',
        'set-8',
        'set-9',
        'set-10',
        'set-11',
        'set-12',
        'set-13',
        'set-14',
        'set-1',
        'set-2',
        'set-3',
        'set-4',
        'set-5',
        'set-6',
        'set-7',
        'set-8',
        'set-9',
        'set-10',
        'set-11',
        'set-12',
        'set-13',
        'set-14',
        'set-2',
        'set-3',
        'set-4',
        'set-5',
        'set-6',
        'set-7',
        'set-8',
        'set-9',
        'set-10',
        'set-11',
        'set-12',
        'set-13',
        'set-14',
        'set-2',
        'set-3',
        'set-4',
        'set-5',
        'set-6',
        'set-7',
        'set-8',
        'set-9',
        'set-10',
        'set-11',
        'set-12',
        'set-13',
        'set-14',
      ],
      indicators: 'center'
    }
  },
  created () {
    var me = this;
    window.onresize = function(){
      me.indicators = window.innerWidth > 800 ? 'center':false;
    };
  },
  computed: {
    ...mapGetters([
      'get_config',
      'get_selected_location'
    ]),
    list() {
      let arr = [];
      let week = this.getWeek;
      const cluster = this.clusters[this.schedule[week-1]];
      for(let i=0; i<cluster.length; i++){
        var url = '/info?lid='+cluster[i][1];
        var target= '_self';
        if(String(cluster[i][1]).length == 0){
          url = '/aanvragen/';
        }
        if(String(cluster[i][1]).substr(0,4) == 'http'){
          url = cluster[i][1];
          target = '_blank'
        }
        arr.push({ image: cluster[i][0], url: url, target: target });
      }
      return arr;
    },
    all_items(){
      let arr = [];
      let sets = Object.keys(this.clusters);
      const me = this;
      sets.forEach(function(el){
        me.clusters[el].forEach(function(item){
          arr.push('images/headers/'+item[0]+'.jpg');
        })
      });
      return arr;
    },
    get_route() {
      return this.$route;
    },
    background() {
      var size = this.get_image_size_multiplier > 1 ? '@2x' : '';
      return 'background-image: url(images/banner'+size+'.jpg)';
    },
    getWeek() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      // Thursday in current week decides the year.
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
      // January 4 is always in week 1.
      var week1 = new Date(date.getFullYear(), 0, 4);
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }
  },
  methods: {
    bannerStats(){
      let statItems = [];
      let statCounts = [];
      this.schedule.forEach(el => {
        this.clusters[el].forEach(el => {
          const item = el[0];
          const i = statItems.findIndex(el => el == item);
          if(i > -1){
            statCounts[i]++;
          }else{
            statItems.push(item);
            statCounts.push(1);
          }
        })
      });
    },
  }
}
</script>
<style>
.slider-indicator-icon{
  border: solid 1px #1C80B5;
  background-color: white !important;
}
.slider-indicator-icon.slider-indicator-active{
  background-color: #1C80B5 !important;
}
.slider-indicators,
.slider-btn{
  z-index:99 !important;
}
@media only screen and (max-width: 800px){
  .slider-indicators{
    display:none;
  }
}
</style>