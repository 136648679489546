<template>
  <div class="Nieuwsbrief footer-page">
    <Aside />

    <div class="page_container">
      <div class="page__wrapper">
        <div class="text-XXlarger SecondaryColor CustomFont">Nieuwsbrief</div>
        <div class="spacer small"></div>
          <p>Wil je op de hoogte blijven van alle acties van School en Schoolreis? Neem dan nu een abonnement op de <span style="white-space: nowrap">e-mailnieuwsbrief</span>. Opzeggen kan uiteraard te allen tijde.</p>
          <div class="Tile Active">
            <div class="inputbox">
              <div class="inputlabel">E-MAILADRES</div>
              <input class="inputfield" type="email" v-model="email">
            </div>
            <div class="Message" :class="[responseStyle]">{{serverResponse}}</div>
            <br>
            <div class="HoverButtonWrap" :class="{'Wait': loading}" v-if="responseStyle != 'Success'">
              <div class="HoverButton SecondaryBGColor" @click="submit" style="background-color:#D41326">
                {{submitButtonText}}
              </div>
            </div>
          </div>
      </div>
    </div>

  </div>
</template>

<script>
import Aside from "../components/Aside.vue";

export default {
  name: "Nieuwsbrief",
  components: {
    Aside
  },
  data() {
    return {
      email: '',
      serverResponse: '',
      responseStyle: '',
      loading: false,
    };
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  computed: {
    submitButtonText(){
      return this.loading ? 'bezig...':'Verstuur';
    }
  },
  methods: {
     set_page(p) {
      this.$router.push({path: p});
    },
    submit(){
      const me = this;
      this.postData('https://schoolenschoolreis.nl/mailcamp.php', {email: this.email, format: 'h'}).then(function(res){
        const domparser = new DOMParser();
        const doc = domparser.parseFromString(res, 'text/html');
        const state = doc.getElementsByTagName('h3')[0].textContent;
        me.serverResponse = doc.getElementsByClassName('message')[0].textContent;
        if(state.includes("foutmelding")){
          me.responseStyle = 'Warn';
        }
        if(state.includes("voltooid")){
          me.responseStyle = 'Success';
        }
      })
    },
    postData(url, data){
      const me = this;
      if(!this.loading){
        me.loading = true;
        return new Promise((resolve) => {
          var xhr = new XMLHttpRequest();
          xhr.open("POST", url, true);
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.send(JSON.stringify(data));
          xhr.onreadystatechange = function () {
            if (this.readyState != 4) return;
            if (this.status == 200) {
              resolve(this.responseText);
            }
            me.loading = false;
          };
        });
      }
    }
  }
};
</script>

<style scoped>
.Message{
  font-weight: bold;
}
.Message.Warn{
  color: red;
}
.Message.Success{
  color: green;
}
.HoverButtonWrap.Wait{
  opacity: 0.5;
}
</style>