<template>
    <div>
      <div v-if="page=='form'" class="footer-page form-page">
          <Aside />

          <div class="page_container" style="background:white;position:relative">
            <div class="page__wrapper">
              <div class="text-Xlarger secondary-color CustomFont">Doe mee aan de <img src="/images/gravitrax/gravitrax.png" srcset="/images/gravitrax/gravitrax@2x.png 2x" alt="Gravitrax" style="width:100%;max-width:286px;position:relative;top:4px"> Challenge!</div>
              <div class="spacer small"></div>
              Ravensburger geeft 300 basisscholen <span class="semibold">twee sets GraviTrax cadeau</span> (op=op). Kinderen leren met deze educatieve spellen spelenderwijs creatief om te gaan met zwaartekracht. Om het extra leuk te maken: het sluit naadloos aan bij ‘Marble Mania’. De beide sets zijn gratis. De 300 basischolen hoeven dus niks te betalen. Wat we wel graag in ruil retour ontvangen is een ingevuld review-formulier. Meer informatie hierover sturen we mee met de beide sets.<br>
              <br>
              <div class="flex-two-col">
                <div class="summaryText">
                  <strong>Twee sets: onderbouw én bovenbouw</strong><br>
                  Wie meedoet, ontvangt twee <span class="semibold">starterssets GraviTrax</span>.<br>
                  De ene set is geschikt voor de onderbouw, de andere is geschikt voor de bovenbouw.<br>
                  <br>
                  <strong>GraviTrax op YouTube</strong><br>
                  Meer weten en meer zien? Op YouTube vind je diverse video’s over GraviTrax: <span class="link" @click="showVideo=true">klik hier</span>.<br>
                  <br>
                  <strong>Gratis sets bestellen</strong><br>
                  Zolang de voorraad strekt kunnen de sets besteld worden. Vul onderstaand jullie gegevens in. Binnen enkele weken worden beide sets op jullie school afgeleverd.
                </div>
                <div class="boxPackage desktop">
                  <img src="/images/gravitrax/gravitrax-desktop.png" srcset="/images/gravitrax/gravitrax-desktop@2x.png 2x" alt="Gravitrax box">
                </div>
                <div class="boxPackage mobile">
                  <img src="/images/gravitrax/gravitrax-mobile.png" srcset="/images/gravitrax/gravitrax-mobile@2x.png 2x" alt="Gravitrax box">
                </div>
              </div>

              <div style="margin:10px 0;">Vul je gegevens in:</div>
              <div class="userForm">
                <div class="inputbox" :class="[form.schoolnaam.state]"><div class="inputlabel">NAAM SCHOOL</div><input @blur="checkInput(false)" v-model="form.schoolnaam.value" class="inputfield" type="text" maxlength="64"></div>
                <div class="spacer"></div>
                <div class="inputbox" :class="[form.schooladres.state]"><div class="inputlabel">VESTIGINGSADRES SCHOOL (*)</div><input @blur="checkInput(false)" v-model="form.schooladres.value" class="inputfield" type="text" maxlength="64"></div>
                <div class="inputbox dual">
                  <div class="inputbox" :class="[form.postcode.state]"><div class="inputlabel">POSTCODE</div><input @blur="checkInput(false)" v-model="form.postcode.value" class="inputfield" type="text" maxlength="7"></div>
                  <div class="spacer"></div>
                  <div class="inputbox" :class="[form.plaats.state]"><div class="inputlabel">PLAATS</div><input @blur="checkInput(false)" v-model="form.plaats.value" class="inputfield" type="text" maxlength="64"></div></div>
                <div class="spacer"></div>
                <div class="inputbox" :class="[form.contactpersoon.state]"><div class="inputlabel">CONTACTPERSOON</div><input @blur="checkInput(false)" v-model="form.contactpersoon.value" class="inputfield" type="text" maxlength="64"></div>
                <div class="inputbox" :class="[form.email.state]"><div class="inputlabel">E-MAIL CONTACTPERSOON</div><input @blur="checkInput(false)" v-model="form.email.value" class="inputfield" type="email" id="email" maxlength="64"></div>
                <div class="spacer"></div>
                <div class="inputbox" :class="[form.herhaalemail.state]"><div class="inputlabel">HERHAAL E-MAILADRES</div><input @blur="checkInput(false)" v-model="form.herhaalemail.value" class="inputfield" type="email" id="emailrep" maxlength="64"></div>
              </div>
              <div class="warnings">
                <div v-for="(warning, i) in form.warnings" :key="'warning'+i">{{warning}}</div>
              </div>
              <div style="margin-top:20px">
                <div @click="sendForm" class="HoverButton SecondaryBGColor" :class="{'wait':form.loading}">verstuur</div>
              </div>

              <div style="margin-top:40px;font-size:16px;font-style:italic;padding-right:100px">
                (*) Heeft jullie school of scholenvereniging meerdere vestigingen? Vraag dan voor elke vestiging apart twee sets aan!
              </div>

              <img src="/images/gravitrax/ravensburg-logo.png" srcset="/images/gravitrax/ravensburg-logo@2x.png 2x" alt="Ravensburger" class="ravensburgLogo">
            </div>
          </div>

          <div class="YouTubeOverlay" v-if="showVideo">
            <div class="sluiten" @click="showVideo=false"><img src="/images/close.svg" alt="" style="width:1em;height:1em"> SLUITEN</div>
            <iframe src="https://www.youtube-nocookie.com/embed/TePB-7wpS3Q?si=pMYNTMTV0OQfYLcj&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

      </div>

      <div v-if="page=='dank'" class="footer-page dank-page">
          <Aside />

          <div class="page_container" style="background:white;position:relative">
            <div class="page__wrapper" style="padding-bottom:0">
              <div class="flex-two-col">
                <div class="dankText">
                  <div class="text-Xlarger secondary-color CustomFont">Bedankt voor jullie aanvraag!</div>
                  <div class="spacer small"></div>
                  We hebben jullie aanvraag voor twee gratis sets GraviTrax in goede orde ontvangen. De eerste 300 aanvragen worden gehonoreerd. De sets worden in oktober verzonden aan de basisscholen.<br>
                  <br>
                  <router-link to="/"><a style="color:#1C80B5">Terug naar de homepage</a></router-link>
                </div>
                <div class="boxPackage desktop">
                  <img src="/images/gravitrax/gravitrax-bedankt-desktop.png" srcset="/images/gravitrax/gravitrax-bedankt-desktop@2x.png 2x" alt="Gravitrax box">
                </div>
                <div class="boxPackage mobile">
                  <img src="/images/gravitrax/gravitrax-bedankt-mobile.png" srcset="/images/gravitrax/gravitrax-bedankt-mobile@2x.png 2x" alt="Gravitrax box">
                </div>
              </div>

              <img src="/images/gravitrax/ravensburg-logo.png" srcset="/images/gravitrax/ravensburg-logo@2x.png 2x" alt="Ravensburger" class="ravensburgLogo">
            </div>
          </div>

      </div>

      <div style="position:fixed;background:rgba(0, 0, 0, 0.75);left:0;top:0;right:0;bottom:0;z-index:999;display:flex" id="overlay">
          <div style="margin:auto;color:rgb(255, 255, 255);text-align:center;font-size:30px;line-height:1.3">
          Helaas, alle beschikbare sets zijn besteld! <br>Er kan niet meer aangevraagd worden. 
          </div>
      </div>

    </div>
</template>

<script>
    window.addEventListener('load', function () {
      if(location.hash == '#override'){
        document.getElementById('overlay').style.display = 'none';
      }
    })
  </script>

<style>
:root {
  --form-warn-color: #D41326;
  --form-valid-color: #AFDC4D;
}
.dankText{
  flex:0 1 540px;
}
.summaryText{
  flex:1 1 471px;
}
.userForm{
  display:flex;
  flex-wrap:wrap;
}
.flex-two-col{
  display:flex;
}
.text-Xlarger{
  line-height: 1em;
}
.page__wrapper a, .link{
  color: var(--form-warn-color);
  text-decoration: underline;
  cursor: pointer;
}
.HoverButton {
    color: #fff;
    font-size: larger;
    padding: 10px 50px;
    display: inline-block;
    cursor: pointer;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
    text-align: center;
    text-transform: uppercase;
    background-color: var(--form-warn-color);
    font-family: jumble;
    position: relative;
}

.HoverButton.valid{
  background-color: var(--form-valid-color);
}

.HoverButton.wait{
  pointer-events: none;
  color:#ffffff7e;
}

.HoverButton.wait::after{
    content: '';
    display: block;
    width: 20px;
    background-color: white;
    left: 0;
    top: 0;
    height: 100%;
    position: absolute;
    filter: blur(3px);
    opacity: 0.8;
    animation: buttonLoader 1s infinite linear;
}

.dank-page .boxPackage{
  margin-right: 70px;
}

.boxPackage.mobile{
  display: none;
}

.boxPackage.desktop img{
  width:100%;
  max-width:469px;
}

.boxPackage.mobile img{
  width:100%;
  max-width:369px;
}
@keyframes buttonLoader{
  0%{
    left:-20px;
  }
  100%{
    left:100%;
  }
}

.inputbox.dual{
  display:flex;
  padding:0;
}

.inputbox .inputbox{
  border:none;
  margin-bottom:0;
  background-color:transparent;
}

.inputbox{
  flex: 0 0 calc(50% - 5px);
  max-width: unset;
}

.inputbox.warn{
  background-color: var(--form-warn-color);
  color:white;
}

.inputbox.valid{
  background-color: var(--form-valid-color);
}

.spacer{
  flex:0 0 10px;
}

.semibold{
  font-weight: 600;
}
.footer-page .CustomFont{
  font-size: 48px;
}

.location-navigation{
  display: none;
}

.warnings>div{
    background-color: var(--form-warn-color);
    color:white;
    font-size: 16px;
    padding:5px;
    margin:5px 0;
}

.ravensburgLogo{
  position:absolute;
  right:0;
  bottom:0;
}

.YouTubeOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000b0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.YouTubeOverlay iframe{
  width:1000px;
  height:562px;
}

.sluiten {
    border: solid 1px white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px 20px;
    font-weight: bold;
    background: var(--form-warn-color);
}

@media screen and (max-width:1000px){
  .YouTubeOverlay iframe{
    width:100vw;
    height:56.2vw;
  }
}

@media screen and (max-width:815px){
  .summaryText, .dankText{
    flex: 1 1 auto;
  }
  .flex-two-col{
    flex-direction: column;
  }
  .boxPackage{
    padding-top:2em;
  }
  .boxPackage.desktop{
    display: none;
  }
  .boxPackage.mobile{
    display: block;
  }
}

@media screen and (max-width:655px){
  .inputbox{
    flex: 0 0 calc(100% - 5px);
  }
  .inputbox .inputbox{
    flex: 0 0 calc(50% - 5px);
  }
}

@media screen and (max-width:560px){
  .ravensburgLogo{
    width:120px;
  }
}

@media screen and (max-width:400px){
  .side_layout .layout__container{
    padding:0;
  }
  .side_layout .layout__container .page_container .page__wrapper {
    padding: 10px;
  }

  .go__back{
    margin: 14px;
  }
}
</style>


<script>
import Aside from "../components/Aside.vue";
import { mapGetters } from 'vuex'

export default {
  name: "Gravitrax",
  components: {
    Aside
  },
  data() {
    return {
      page: 'form',
      form:{
        schoolnaam: {value:'', state:'', hint:'Naam School is niet ingevuld.'},
        schooladres: {value:'', state:'', hint:'Vestigingsadres school dient straat en huisnummer te hebben.'},
        postcode: {value:'', state:'', hint:'Postcode is niet volledig ingevuld.'},
        plaats: {value:'', state:'', hint:'Plaats is niet ingevuld.'},
        contactpersoon: {value:'', state:'', hint:'Contactpersoon is niet ingevuld.'},
        email: {value:'', state:'', hint:'E-mailadres contactpersoon is niet volledig ingevuld.'},
        herhaalemail: {value:'', state:'', hint:'Herhaal e-mailadres komt niet overeen met e-mailadres contactpersoon.'},
        warnings: [],
        passed: false,
        loading: false,
      },
      showVideo:false,
    };
  },
  computed: {
    ...mapGetters([
      'get_config',
    ]),
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
    checkInput(checkEmpty){
      this.form.warnings = [];
      this.form.passed = true;
      //check all inputs
      ['schoolnaam','schooladres','postcode','plaats','contactpersoon','email','herhaalemail'].forEach(el =>{
        if(this.form[el].value.trim().length == 0){
          this.form.passed = false;
        }
        this.form[el].state = '';
        if(this.form[el].value.trim().length > 0){
          //must be filled
          if(['schoolnaam','plaats','contactpersoon'].some(n => n == el)){
            this.form[el].state = 'valid';
          }
          //must be address
          if(el == 'schooladres'){
            this.form[el].state = /\d/.test(this.form[el].value.trim()) ? 'valid':'warn';
          }
          //must be zipcode
          if(el == 'postcode'){
            const zipcode = this.form[el].value.trim().split(' ').join('');
            this.form[el].state = 'valid';
            if(isNaN(zipcode.substr(0,4)) || zipcode.substr(0,4).length != 4){
              this.form[el].state = 'warn';
            }
            if(/\d/.test(zipcode.substr(4,2)) || zipcode.substr(4,2).length != 2){
              this.form[el].state = 'warn';
            }
          }
          //must be email
          if(el == 'email'){
            this.form[el].state = 'valid';
            const emailParts = this.form[el].value.trim().split('@');
            if(emailParts.length != 2){
              this.form[el].state = 'warn';
            }else{
              if(emailParts[0].length == 0 || emailParts[1].length == 0){
                this.form[el].state = 'warn';
              }
              const domainParts = emailParts[1].split('.');
              if(domainParts.length < 2){
                this.form[el].state = 'warn';
              }
            }
          }
          //email must be same
          if(el == 'herhaalemail'){
            if(this.form[el].value.trim().trim() != this.form['email'].value.trim()){
              this.form[el].state = 'warn';
            }else{
              this.form[el].state = 'valid';
            }
          }
        }else{
          if(checkEmpty){
            this.form[el].state = 'warn';
          }
        }
        if(this.form[el].state == 'warn'){
          this.form.warnings.push(this.form[el].hint);
        }

      })
    },
    sendForm(){
      this.checkInput(true);
      if(this.form.warnings.length == 0){
        this.form.loading = true;
        this.postData({
          cmd: 'sendform',
          schoolnaam: this.form.schoolnaam.value,
          schooladres: this.form.schooladres.value,
          postcode: this.form.postcode.value,
          plaats: this.form.plaats.value,
          contactpersoon: this.form.contactpersoon.value,
          email: this.form.email.value,
        })
      }
    },
    postData(data) {
        const me = this;
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "/php/gravitrax/interface.php", true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                me.afterPostData(JSON.parse(xhr.response));
            }
        };
        xhr.send(JSON.stringify(data));
    },
    afterPostData(data){
        if(data.cmd == 'sendform'){
            this.page = 'dank';
        }
    },
  },
};
</script>


