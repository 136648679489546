<template>
  <div id="app" ref="app_window" :class="[get_window.navigation && get_window.is_mobile ? 'navigation_open' : '', current_page, get_options.is_map_showing ? 'map_open':'']">
    <Header :class="[get_window.is_mobile ? 'mobile' : '']" />
    <Banner image="placeholder-banner.jpg" v-if="show_banner" style="box-shadow: 0 3px 0 0 rgb(45 45 45 / 25%)"/>
    <template v-if="on_home_page">
      <div class="introduction block">
        <div class="container">
          <div class="block-centered text-block text-center intro-title">Ga je mee op schoolreis?</div>
          <div class="block-centered text-block text-center">De allerleukste schoolreisjes, overzichtelijk op een rij. Met een extra service voor docenten en oudercommissies: neem vooraf zelf een kijkje! Vraag vrijkaarten of rondleidingen aan!</div>
          <div class="spacer small"></div>
          <template v-if="get_window.is_mobile">
            <Aside />
          </template>
        </div>
      </div>
    </template>

    <div :class="['block', 'layout', 'side_layout', get_window.is_mobile ? 'mobile' : '']">
      <div :class="['container', 'layout__container', $router.path == '/' ? 'location' : '']">
        <router-view></router-view>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Aside from "@/components/Aside.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'app',
  components: {
    Header,
    Banner,
    Aside,
    Footer
  },
  created() {
    if (window.location.host.includes('localhost') || window.location.host.includes('192.168.102')) {
      this.set_env("https://schoolenschoolreis.nl/app");
    } else {
      this.set_env("/app");
    }

    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    window.onscroll = function(){
      if(window.location.pathname == '/'){
        window.rememberScroll = window.scrollY;
      }
    }
    this.init().then((response) => {
      this.set_loading(false);
      if (response.mollie_status.length > 0) {
        this.payment_status(response.mollie_status).then((res) => {
          switch (res.data.payment_status) {
            case 'paid':
              this.$router.push({path: '/response'});
              this.set_loading(false);
              break;
            case 'cancelled':
              this.set_error_msg({
                title: "Betaling geannuleerd",
                message: "U heeft de betaling geannuleerd."
              })
              this.$router.push({path: '/error'});
              this.set_loading(false);
              break;
            case 'failed':
              this.set_error_msg({
                title: "Betaling mislukt",
                message: "De betaling is helaas mislukt.<br><br>Bestel <a href='/'>hier</a> opnieuw uw kaarten."
              })
              this.$router.push({path: '/error'});
              this.set_loading(false);
              break;
            case 'expired':
              this.set_error_msg({
                title: "Betaling verlopen",
                message: "De betaling is niet op tijd afgerond.<br><br>Bestel <a href='/'>hier</a> opnieuw uw kaarten."
              })
              this.$router.push({path: '/error'});
              this.set_loading(false);
              break;
            case 'attempted':
              this.set_error_msg({
                title: "Betaling staat open",
                message: "Uw betaling is nog niet verwerkt, dit kan maximaal 15 minuten duren. Bestel <a href='/'>hier</a> opnieuw uw kaarten."
              })
              this.$router.push({path: '/error'});
              this.set_loading(false);
              break;
            default:
              this.$router.push({path: '/'});
              this.set_loading(false);
              break;
          }
        })
      } else {
        // this.get_locations().then((res) => {
        //   if (res == 'success') {
        //     this.set_loading(false);
        //   }
        // });
        // this.set_loading(false);
      }
    }).finally(() => {
      this.onResize();
      this.initLinkTracking();
    })
  },
  computed: {
    ...mapGetters([
      'get_window',
      'get_options',
    ]),
    on_home_page() {
      return this.$route.path == '/';
    },
    show_banner() {
      return this.$route.path == '/' || this.$route.path == '/response' || this.$route.path == '/error';
    },
    current_page(){
      return 'route_'+this.$route.path.replace('/','');
    }
  },
  methods: {
    ...mapActions({
      init: 'init',
      set_env: 'set_env',
      payment_status: 'payment_status',
      get_locations: 'get_locations',
      is_mobile: 'is_mobile',
      is_large: 'is_large',
      set_loading: 'set_loading',
      set_error_msg: 'set_error_msg'
    }),
    onResize() {
      window.innerWidth < 912 ? this.is_mobile(true) : this.is_mobile(false);
    },
    initLinkTracking() {
      // auto external link tracking
      window.addEventListener('click', event => {
        let { target } = event;
        let notAnchor = target.tagName.toLowerCase() !== 'a';
        while (notAnchor){
          target = target.parentNode;
          if(!target) return;
          if('tagName' in target == false) return;
          notAnchor = target.tagName.toLowerCase() !== 'a';
        }
        // handle only links that reference external resources
        if (target && target.href) {
          const { button, defaultPrevented } = event;
          // don't handle when preventDefault called
          if (defaultPrevented) return
          // don't handle right clicks
          if (button !== undefined && button !== 0) return
        }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>