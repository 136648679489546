<template>
  <div class="VeelgesteldeVragen">
    <Aside />

    <div class="page_container">
      <div class="page__wrapper">
        <div class="text-XXlarger SecondaryColor upperCase CustomFont text-bold">Veelgestelde Vragen</div>
        <div class="spacer small"></div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus enim felis, commodo tempus faucibus quis, tempor quis lorem. Phasellus scelerisque libero sit amet mi dapibus, ut gravida libero vestibulum. Aenean ac commodo erat. Nunc eu nisl eu nulla feugiat porta ut a justo. Donec tempor placerat ultricies. Praesent neque ligula, vehicula non eleifend eget, accumsan id quam. Phasellus ac tristique diam. Nulla facilisi. Donec in ex vel velit vulputate mollis.
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Aside from "../components/Aside.vue";

export default {
  name: "VeelgesteldeVragen",
  components: {
    Aside
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters([
      'get_config'
    ])
  }
};
</script>


