<template>
  <div class="block footer" ref="Footer">
    <div class="container">
      <div class="text-center footer_tag" style="font-size: 13px; line-height: 1.4em">
        <a target="_blank" href="/privacyverklaring.pdf">Privacystatement</a><br>
        © {{currentYear}}. Alle rechten voorbehouden.<br>
        <br>
        BASIS ActivatieMarketing<br>
        Prinsengracht 168<br>
        1016 HA Amsterdam<br>
        KVK 34084610<br>
        Btw NL807997699B01
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setTimeout } from 'timers';

export default {
  name: "Footer",
  props: {},
  data() {
    return {
      details: false
    };
  },
  computed: {
    ...mapGetters([
      'get_window',
    ]),
    currentYear(){
      return new Date().getFullYear();
    }
  },
  methods: {
    show_details() {
      this.details = !this.details;
      if(this.details){
        setTimeout(() => {
          this.$scrollTo('#BasisDetails');
        }, 100);
      }
    }
  }
};
</script>
