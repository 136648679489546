<template>
  <div :class="['aside', get_window.is_mobile ? 'mobile' : '']" id="filters">

 <!-- INFO BAR ON OTHER PAGES -->
    <template v-if="!home_page">
      <div class="go__back" @click="ga_terug"><img src="images/icon-chevron-left.svg" alt=""> GA TERUG</div>

      <div class="location-navigation" v-if="get_window.is_mobile">
        <div class="prev loc-nav" @click="getLocation(-1)"><img src="images/icon-arrow-left.svg" alt=""></div>
        <div class="next loc-nav" @click="getLocation(1)"><img src="images/icon-arrow-right.svg" alt=""></div>
      </div>

      <template v-if="show_info_bar && !get_window.is_mobile">
        <div class="info_bar">
          <div class="info_bar_left">
            <div class="info_bar_logo" :style="logo"></div>
          </div>
          <div class="spacer small"></div>
          <div class="info_bar_right">
            <div class="SecondaryColor CustomFont text-small" v-if="has_open_times">Openingstijden</div>
            <div v-html="get_selected_location.opentimes" class="text-smaller"></div>
            <div class="spacer small"></div>
            <div class="SecondaryColor CustomFont text-small">Adres</div>
            <div v-if="get_selected_location.multiple_locations" class="text-smaller">
              <div >Meerdere locaties</div>
            </div>
            <div v-else class="text-smaller">
              <div v-html="get_selected_location.address"></div>
              <div>{{ get_selected_location.zipcode }} {{ get_selected_location.city}}</div>
            </div>
            <div class="spacer small"></div>
            <div class="SecondaryColor CustomFont text-small">Website</div>
            <a :href="location_url" target="_blank" class="text-smaller" style="display:block">{{location_domain}}</a>
            <div class="spacer small"></div>
            <div class="SecondaryColor CustomFont">Telefoon</div>
            <div class="text-smaller">{{get_selected_location.phone}}</div>
          </div>
        </div>
      </template>
    </template>

    <!-- HOME PAGE FILTERS -->
    <template>
       <template v-if="get_window.is_mobile && home_page">

        <div class="button show_filters" 
          @click="show_filters = !show_filters">
          {{show_filters ? 'Verberg filters' : 'Maak een selectie'}}

          <span v-if="!show_filters">
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="14.688" height="9.07" viewBox="0 0 14.688 9.07">
              <path id="ic_expand_more_24px" d="M18.962,8.59,13.344,14.2,7.726,8.59,6,10.316l7.344,7.344,7.344-7.344Z" transform="translate(-6 -8.59)" fill="#fff"/>
            </svg>
          </span>

          <span v-else>
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="14.688" height="9.07" viewBox="0 0 14.688 9.07">
              <path id="ic_expand_more_24px" d="M18.962,8.59,13.344,14.2,7.726,8.59,6,10.316l7.344,7.344,7.344-7.344Z" transform="translate(20.688 17.66) rotate(180)" fill="#fff"/>
            </svg>
          </span>

        </div>

        <div class="spacer small"></div>
      </template>

      <!-- DESKTOP FILTER -->
      <template v-if="!get_window.is_mobile">
        <div class="CustomFont" v-if="home_page">
          <div class="aside__filter map text-medium">
            <div :class="['list_map_button', 'button_list', get_options.is_map_showing?'':'active']" @click="toggle_map_visibility(false)">
              <svg class="icon-pin" xmlns="http://www.w3.org/2000/svg" width="23.438" height="18.375" viewBox="0 0 23.438 18.375">
                <path id="Path_140" data-name="Path 140" d="M11.719-15.656a.27.27,0,0,0,.188-.094A.27.27,0,0,0,12-15.937v-1.125a.27.27,0,0,0-.094-.187.27.27,0,0,0-.187-.094H-5.719a.27.27,0,0,0-.187.094A.27.27,0,0,0-6-17.062v1.125a.27.27,0,0,0,.094.188.27.27,0,0,0,.188.094ZM12-8.437a.27.27,0,0,1-.094.188.27.27,0,0,1-.187.094H-5.719a.27.27,0,0,1-.187-.094A.27.27,0,0,1-6-8.437V-9.562a.27.27,0,0,1,.094-.187.27.27,0,0,1,.188-.094H11.719a.27.27,0,0,1,.188.094A.27.27,0,0,1,12-9.562Zm0,7.5a.27.27,0,0,1-.094.188.27.27,0,0,1-.187.094H-5.719A.27.27,0,0,1-5.906-.75.27.27,0,0,1-6-.937V-2.062a.27.27,0,0,1,.094-.187.27.27,0,0,1,.188-.094H11.719a.27.27,0,0,1,.188.094A.27.27,0,0,1,12-2.062ZM-9.75-18.187a1.627,1.627,0,0,1,1.2.492,1.627,1.627,0,0,1,.492,1.2,1.627,1.627,0,0,1-.492,1.2,1.627,1.627,0,0,1-1.2.492,1.627,1.627,0,0,1-1.2-.492,1.627,1.627,0,0,1-.492-1.2,1.627,1.627,0,0,1,.492-1.2A1.627,1.627,0,0,1-9.75-18.187Zm0,7.5a1.627,1.627,0,0,1,1.2.492A1.627,1.627,0,0,1-8.062-9a1.627,1.627,0,0,1-.492,1.2,1.627,1.627,0,0,1-1.2.492,1.627,1.627,0,0,1-1.2-.492A1.627,1.627,0,0,1-11.437-9a1.627,1.627,0,0,1,.492-1.2A1.627,1.627,0,0,1-9.75-10.687Zm0,7.5a1.627,1.627,0,0,1,1.2.492,1.627,1.627,0,0,1,.492,1.2A1.627,1.627,0,0,1-8.555-.3a1.627,1.627,0,0,1-1.2.492,1.627,1.627,0,0,1-1.2-.492,1.627,1.627,0,0,1-.492-1.2,1.627,1.627,0,0,1,.492-1.2A1.627,1.627,0,0,1-9.75-3.187Z" transform="translate(11.438 18.188)" :fill="get_options.is_map_showing?'#1C80B5':'#fff'"/>
              </svg>
              <span>Lijst</span>
            </div>
            <div :class="['list_map_button', 'button_map', !get_options.is_map_showing?'':'active']" @click="toggle_map_visibility(true)">
              <svg class="icon-pin" xmlns="http://www.w3.org/2000/svg" width="25.875" height="20.136" viewBox="0 0 25.875 20.136">
                <path id="Path_141" data-name="Path 141" d="M10.916-18.553a1.363,1.363,0,0,1,1.37.112,1.347,1.347,0,0,1,.651,1.19V-2.875a1.417,1.417,0,0,1-.225.764,1.385,1.385,0,0,1-.629.539L5.615,1.3a1.42,1.42,0,0,1-.988.09l-9.568-2.74L-10.916,1.3a1.363,1.363,0,0,1-1.37-.112A1.347,1.347,0,0,1-12.937,0V-14.375a1.417,1.417,0,0,1,.225-.764,1.385,1.385,0,0,1,.629-.539l6.469-2.875a1.42,1.42,0,0,1,.988-.09L4.941-15.9ZM-4.312-17.025V-2.65L4.313-.225V-14.6ZM-11.5,0l5.75-2.561V-16.936l-5.75,2.561Zm23-2.875V-17.25L5.75-14.689V-.314Z" transform="translate(12.938 18.693)" :fill="!get_options.is_map_showing?'#1C80B5':'#fff'"/>
              </svg>
              <span>Kaart</span>
            </div>
          </div>
        </div>
        <div v-if="show_categories">
          <div class="spacer small"></div>
          <div class="upperCase SecondaryColor text-bold asideTitle">Kies een categorie</div>
          <div :class="['aside__filter', get_active_category === 'All' ? 'active' : '']" @click="filter_by_location('All')">Alles</div>
          <div :class="['aside__filter', get_active_category === filter ? 'active' : '']" v-for="(filter, index) in get_categories" :key="'filter-' + index" @click="filter_by_location(filter)">{{ filter }}</div>
          <div class="spacer small"></div>
        </div>
        <div v-if="show_categories">
          <div v-if="!get_options.is_map_showing">
            <div class="upperCase SecondaryColor text-bold asideTitle">Zoek op afstand</div>
            <label id="sort" class="aside__option">
              <span @click="reset_sort()" v-if="get_options.sorting" class="reset_state"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>
              <div @click="sort_distance(true)"><img src="images/icon-sort.svg" alt=""></div>
              <input @keyup.enter="sort_distance(true)" :class="['aside__sort', get_options.sorting ? 'no_select' : '']" type="text" placeholder="0000XX of Plaats" v-model="distance" :disabled="get_options.sorting">
            </label>
          </div>
           <div class="spacer small"></div>
          <div class="upperCase SecondaryColor text-bold asideTitle">Zoek op uitstapje</div>
          <label id="search" class="aside__option">
            <span @click="reset_search()" v-if="get_options.searching" class="reset_state"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>
            <div @click="find_location()"><img src="images/icon-search.svg" alt=""></div>
            <input @keyup.enter="find_location()" :class="['aside__search', get_options.searching ? 'no_select' : '']" type="text" placeholder="Uitstapje" v-model="search" :disabled="get_options.searching">
          </label>
        </div>
      </template>
      
      <!-- MOBILE FILTER -->
      <template v-if="get_window.is_mobile && show_filters">
        <div class="aside__filter map text-medium CustomFont">
          <div :class="['list_map_button', 'button_list', get_options.is_map_showing?'':'active']" @click="toggle_map_visibility(false)">
            <svg class="icon-pin" xmlns="http://www.w3.org/2000/svg" width="23.438" height="18.375" viewBox="0 0 23.438 18.375">
              <path id="Path_140" data-name="Path 140" d="M11.719-15.656a.27.27,0,0,0,.188-.094A.27.27,0,0,0,12-15.937v-1.125a.27.27,0,0,0-.094-.187.27.27,0,0,0-.187-.094H-5.719a.27.27,0,0,0-.187.094A.27.27,0,0,0-6-17.062v1.125a.27.27,0,0,0,.094.188.27.27,0,0,0,.188.094ZM12-8.437a.27.27,0,0,1-.094.188.27.27,0,0,1-.187.094H-5.719a.27.27,0,0,1-.187-.094A.27.27,0,0,1-6-8.437V-9.562a.27.27,0,0,1,.094-.187.27.27,0,0,1,.188-.094H11.719a.27.27,0,0,1,.188.094A.27.27,0,0,1,12-9.562Zm0,7.5a.27.27,0,0,1-.094.188.27.27,0,0,1-.187.094H-5.719A.27.27,0,0,1-5.906-.75.27.27,0,0,1-6-.937V-2.062a.27.27,0,0,1,.094-.187.27.27,0,0,1,.188-.094H11.719a.27.27,0,0,1,.188.094A.27.27,0,0,1,12-2.062ZM-9.75-18.187a1.627,1.627,0,0,1,1.2.492,1.627,1.627,0,0,1,.492,1.2,1.627,1.627,0,0,1-.492,1.2,1.627,1.627,0,0,1-1.2.492,1.627,1.627,0,0,1-1.2-.492,1.627,1.627,0,0,1-.492-1.2,1.627,1.627,0,0,1,.492-1.2A1.627,1.627,0,0,1-9.75-18.187Zm0,7.5a1.627,1.627,0,0,1,1.2.492A1.627,1.627,0,0,1-8.062-9a1.627,1.627,0,0,1-.492,1.2,1.627,1.627,0,0,1-1.2.492,1.627,1.627,0,0,1-1.2-.492A1.627,1.627,0,0,1-11.437-9a1.627,1.627,0,0,1,.492-1.2A1.627,1.627,0,0,1-9.75-10.687Zm0,7.5a1.627,1.627,0,0,1,1.2.492,1.627,1.627,0,0,1,.492,1.2A1.627,1.627,0,0,1-8.555-.3a1.627,1.627,0,0,1-1.2.492,1.627,1.627,0,0,1-1.2-.492,1.627,1.627,0,0,1-.492-1.2,1.627,1.627,0,0,1,.492-1.2A1.627,1.627,0,0,1-9.75-3.187Z" transform="translate(11.438 18.188)" :fill="get_options.is_map_showing?'#1C80B5':'#fff'"/>
            </svg>
            <span>Lijst</span>
          </div>
          <div :class="['list_map_button', 'button_map', !get_options.is_map_showing?'':'active']" @click="toggle_map_visibility(true)">
            <svg class="icon-pin" xmlns="http://www.w3.org/2000/svg" width="25.875" height="20.136" viewBox="0 0 25.875 20.136">
              <path id="Path_141" data-name="Path 141" d="M10.916-18.553a1.363,1.363,0,0,1,1.37.112,1.347,1.347,0,0,1,.651,1.19V-2.875a1.417,1.417,0,0,1-.225.764,1.385,1.385,0,0,1-.629.539L5.615,1.3a1.42,1.42,0,0,1-.988.09l-9.568-2.74L-10.916,1.3a1.363,1.363,0,0,1-1.37-.112A1.347,1.347,0,0,1-12.937,0V-14.375a1.417,1.417,0,0,1,.225-.764,1.385,1.385,0,0,1,.629-.539l6.469-2.875a1.42,1.42,0,0,1,.988-.09L4.941-15.9ZM-4.312-17.025V-2.65L4.313-.225V-14.6ZM-11.5,0l5.75-2.561V-16.936l-5.75,2.561Zm23-2.875V-17.25L5.75-14.689V-.314Z" transform="translate(12.938 18.693)" :fill="!get_options.is_map_showing?'#1C80B5':'#fff'"/>
            </svg>
            <span>Kaart</span>
          </div>
        </div>
        <div class="spacer small"></div>
        <div class="CustomFont">
          <div class="upperCase SecondaryColor text-bold asideTitle">Kies een categorie</div>
          <div :class="['aside__filter', 'text-center', get_active_category === 'All' ? 'active' : '']" @click="filter_by_location('All')">Toon alles</div>
          <div :class="['aside__filter', 'text-center', get_active_category === filter ? 'active' : '']" v-for="(filter, index) in get_categories" :key="'filter-' + index" @click="filter_by_location(filter)"><i :class="to_lowercase(filter) + 'icon-'"></i> {{ filter }}</div>
        </div>
        <div class="spacer small"></div>
        <div>
          <div v-if="!get_options.is_map_showing">
            <div class="upperCase SecondaryColor text-bold asideTitle">Sorteer op afstand</div>
            <label id="sort" class="aside__option">
              <span @click="reset_sort()" v-if="get_options.sorting" class="reset_state"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>
              <div @click="sort_distance(true)"><img src="images/icon-sort.svg" alt=""></div>
              <input @keyup.enter="sort_distance(true)" :class="['aside__sort', get_options.sorting ? 'no_select' : '']" type="text" placeholder="0000XX of Plaats" v-model="distance" :disabled="get_options.sorting">
            </label>
          </div>

          <div class="spacer small"></div>
          <div class="upperCase SecondaryColor text-bold asideTitle">Zoek op uitstapje</div>

          <label id="search" class="aside__option">
            <span @click="reset_search()" v-if="get_options.searching" class="reset_state"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>
            <div @click="find_location()"><img src="images/icon-search.svg" alt=""></div>
            <input class="aside__search" type="text" placeholder="Uitstapje" v-model="search" :disabled="get_options.searching">
          </label>
        </div>
      </template>

    </template>

   

    <div class="spacer medium"></div>

    <a :href="banner.url" target="_blank" v-if="home_page && !get_window.is_mobile">
      <img :src="banner.image" alt="" style="width:240px">
    </a>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: "Aside",
  components: {},
  props: {},
  data() {
    return {
      show_filters: false,
      distance: '',
      sorted: false,
      search: '',
      searching: false,
      banner: {image: '', url: ''},
      all_banners:[
        {image: 'BillyBird.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1030'},
        {image: 'Banner-Blijdorp-240x322.jpg', url: 'https://schoolenschoolreis.nl/info?lid=2613'},
        {image: 'S&S_FunForest_Banner_2022.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1059'},
        {image: 'S&S_NMM_banner_2022.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1080'},
        {image: 'Banner_Oud_Valkeveen.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1099'},
        {image: 'SnowWorld.jpg', url: 'https://www.snowworld.com/nl'},
        {image: '01-Banner-Drievliet-240x322.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1056'},
        {image: 'S&S_Slagharen_banner_2022.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1026'},
        {image: 'Airborne-2023.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1022'},
        {image: 'Dungeon-2023.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1107'},
        {image: 'Tussauds-2023.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1075'},
        {image: 'Museon-2023.png', url: 'https://schoolenschoolreis.nl/info?lid=1078'},
        {image: 'TIH-2023.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1108'},
        {image: 'Bodyworlds-2023.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1032'},
        {image: 'Ripley-2023.jpg', url: 'https://schoolenschoolreis.nl/info?lid=1091'},
      ],
    };
  },
  mounted() {
    this.distance = this.get_options.distance;
    this.sort_distance(false);
    this.search = this.get_options.search;
    const bannersCookie = 'aside-banners-2023-01';
    let banners = Cookies.get(bannersCookie);
      if(!banners){
        banners = [];
        for(let i=0; i<this.all_banners.length; i++){
          banners.push(i);
        }
        banners = this.shuffle(banners);
      }else{
        banners = banners.split('|');
      }
      const current = banners.pop();
      Cookies.set(bannersCookie, banners.join('|'), { expires: 365 });
      this.banner.image = 'images/banners/'+this.all_banners[current].image;
      const binder = String(this.all_banners[current].url).indexOf('https://schoolenschoolreis.nl') == 0 ? '&' : '?';
      this.banner.url = this.all_banners[current].url+binder+'utm_source=schoolenschoolreis&utm_medium=banner&utm_campaign=2023';
  },
  computed: {
    ...mapGetters([
      'get_window',
      'get_options',
      'get_selected_location',
      'get_items_in_category',
      'get_config',
      'get_image_size_multiplier',
      'get_all_location_data',
    ]),
    show_categories() {
       return ["/", "/info"].indexOf(this.$route.path) > -1;
    },
    location_url(){
      return 'http://'+this.get_selected_location.url;
    },
    location_domain(){
      let url = String(this.get_selected_location.url);
      url = url.replace('www.','');
      url = url.split('/')[0];
      return url;
    },
    logo() {
      let width = 184 * this.get_image_size_multiplier;
      let height = 140 * this.get_image_size_multiplier;
      if(!this.get_selected_location.logo){
        return 'background-image: url("/images/loader.gif")';
      }
      return 'background-image: url("https://cdn.basisam.nl/images/get.php?f='+this.get_selected_location.logo+'&w='+width+'&h='+height+'&u=0&e=png&d='+this.get_config.images_cdn+'")';
    },
    home_page() {
      return this.$route.path == '/';
    },
    get_categories() {
      return this.get_options.categories;
    },
    get_active_category() {
      return this.get_options.active_category;
    },
    get_map_toggle_string() {
      return this.get_options.is_map_showing ? 'Bekijk op lijst' : 'Bekijk op kaart';
    },
    show_info_bar() {
      return this.$route.path == '/info' || this.$route.path == '/order' || this.$route.path == '/summary'
    },
    has_open_times(){
      if(this.get_selected_location.hasOwnProperty('opentimes')){
        if(this.get_selected_location.opentimes.length > 0){
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    show_filters(val){
      if(val){
        this.$scrollTo('#filters');
      }
    }
  },
  methods: {
    ...mapActions({
      reset_order: 'reset_order',
      reset_sorting: 'reset_sorting',
      calc_distances: 'calc_distances',
      sort_locations: 'sort_locations',
      filter_locations: 'filter_locations',
      toggle_map: 'toggle_map',
      get_locations: 'get_locations',
      go_back: 'go_back'
    }),
    getLocation(direction) {
      var all = this.get_all_location_data;
      var curridx = all.findIndex(p => p.id == this.get_selected_location.id);
      var newidx = direction > 0 ? (curridx + 1) : (curridx - 1);

      var nextID;
      if (all.length - 1 < newidx) {
        nextID = all[2].id;
      } else if (newidx < 2) {
        nextID = all[all.length - 1].id;
      } else {
        nextID = all[newidx].id;
      }
      this.$router.push({path: '/info', query: { lid: `${nextID}` }});
    },
    shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    },
    ga_terug() {
      if(window.location.pathname == '/gravitrax'){
        window.location.href = '/';
      }else{
        this.go_back(this.$router);
      }
    },
    get_number_price(amount) {
      return (Number(amount) / 100).toFixed(2).replace(".", ",");
    },
    sort_distance(go_home) {
      if(this.distance.length > 1){
        if(!this.home_page && go_home){
          this.$router.push({path: '/', meta:{skip_history:0}});
        }
        this.show_filters = false;
        this.$store.commit('ADD_TO_OPTIONS', {
          key: 'distance',
          value: this.distance
        });
        this.calc_distances(this.distance);
      }
    },
    reset_sort() {
      this.show_filters = false;
      this.get_locations().then(() => {
        this.$store.commit('RESET_SORTING');
        this.distance = "";
      });
    },
    filter_by_location(filt) {
       if(!this.home_page){
          this.$router.push({path: '/', meta:{skip_history:0}});
        }
      this.show_filters = false;
      if (filt == 'All' ) { 
        this.$store.commit('RESET_SEARCH');
        this.search = '' 
      }
      this.filter_locations(filt);
    },
    find_location() {
       if(!this.home_page){
          this.$router.push({path: '/', meta:{skip_history:0}});
        }
      this.show_filters = false;
      if (this.search.length > 1) {
        this.$store.commit('ADD_TO_OPTIONS', {
          key: 'search',
          value: this.search
        });
        this.filter_locations(this.search);
      }
    },
    reset_search() {
      this.show_filters = false;
      this.search = "";
      this.filter_locations('All');
    },
    to_lowercase(string) {
      if (string != null) {
        return string.toLowerCase();
      }
    },
    toggle_map_visibility(show) {
      this.show_filters = false;
      this.toggle_map(show);
    }
  }
};
</script>